import "./index.scss";
import "@/asset/lib/touch.js";
import "@/js/common.js";
$("#header-icon").tap(function () {
  $("#g_menu").toggleClass("open");
});

$("li.haschild").tap(function () {
  var $this = $(this);
  if ($this.hasClass("open")) {
    $this.removeClass("open");
  } else {
    $("li.haschild").removeClass("open");
    $this.addClass("open");
  }
});
