/*
 * @Description: 
 * @Version: 2.0
 * @Date: 2020-06-15 14:12:37
 * @LastEditors: *
 * @LastEditTime: 2020-06-30 16:41:35
 */ 
import "../asset/lib/swiper.css";
import "@/scss/public.scss";

// 鎴彇瀛楃涓插墠鍑犱綅
function cutString(str, num) {
  if (
    str !== null &&
    str !== undefined &&
    (typeof str == "string" || typeof str == "number")
  ) {
    return str.substring(0, num);
  } else {
    return str;
  }
}

document.documentElement.style.fontSize =
  (document.documentElement.clientWidth / 414) * 100 + "px";
document.addEventListener(
  "DOMContentLoaded",
  function (e) {
    document.documentElement.style.fontSize =
      (document.documentElement.clientWidth / 414) * 100 + "px";
  },
  false
);

//娴忚鍣ㄧ獥鍙ｅぇ灏忓彉鍖栨椂閲嶆柊璁＄畻楂樺害
$(window).resize(function () {
  document.documentElement.style.fontSize =
    (document.documentElement.clientWidth / 414) * 100 + "px";
  // console.log("灏哄鍙樺寲");
});

$(document).ready(function() {
  $('.header').tap(function () {
    event.stopPropagation()
  });
  $(document).tap(function(){
    $('.header ul').removeClass('open')
  })
});

// url鍙傛暟鑾峰彇
export function getURLParameter(url, paramName) {
  var urlParams = url.split("?")[1]; //鎴栬€卽rl.search鑾峰彇鍙傛暟瀛楃涓瞈r
  var paramArray = urlParams.split("&");
  var len = paramArray.length;
  var paramObj = {};//json瀵硅薄
  var arr = [];//鏁扮粍瀵硅薄
  var key
  for (var i = 0; i < len; i++) {
    arr = paramArray[i].split("=");
    paramObj[arr[0]] = arr[1];
  }
  for (key in paramObj) {
    if (key == paramName) {
      return paramObj[paramName];
      break;
    }
  }
}
